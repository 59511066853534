import { Api } from '@/core/api.js'
import { db_timers, db_checks_to_sync } from '@/database'
import { Notification } from '@/core/notification.js'
import { v4 as uuidv4 } from 'uuid'
import i18n from '@/i18n'
import store from '@/store'
const notification = new Notification()

const api = new Api()

const getDefaultState = () => {
    return {
        timers: null,
        checksToSync: [],
        interval: null
    }
}

// initial state
const state = getDefaultState()

const getters = {
    getTimers(state, rootState) {
        return state.timers
    },

    getTimerById: (state, rootState) => (id) => {
        return state.timers.find((timer) => timer.id === id)
    },
    getCategories(state, rootState) {
        return state.categories
    },
    getTimersPendings(state, rootState) {
        if (state.timers) return state.timers.filter((time) => time.isAvailable).sort((a, b) => a.previousAlarm - b.previousAlarm)
        else return []
    },
    getTimersCompleted(state, rootState) {
        if (state.timers) return state.timers.filter((time) => !time.isAvailable).sort((a, b) => a.nextAlarm - b.nextAlarm)
        else return []
    }
}

const actions = {
    loadTimers(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }
        // const fakeTimers = [
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz09',
        //         name: 'Limpieza de trapos',
        //         description: 'Meter trapos en la lavadora',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz10',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz11',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     }
        // ]

        return api
            .get('timer-op/timers')
            .then((response) => {
                const timersArr = Object.values(response.data) //.filter((timer) => timer.id == 'NDc1OEcwOTZ2TDduTkg2cHlWcHBpdz09')

                // formattear y settear los Timers:
                context.dispatch('formatTimers', timersArr)

                // context.dispatch('formatTimers', fakeTimers)

                // actualizar los timers (pending alarm, next alarm, isAvailable):
                context.dispatch('updateTimers').then(() => {
                    const timers = [...context.getters.getTimers]
                    // log('timers', timers)
                    context.dispatch('generateNotifications', timers)
                })

                // setear función para chequear timers y actualizarlos por segundo:
                context.dispatch('setupInterval')
            })
            .catch((error) => {
                // console.error(error)
                // return loadTimersOffline(context)
            })
    },

    loadCategories(context, params) {
        return api
            .get('admin/timer-op/categories')
            .then((response) => {
                context.commit('setCategories', response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    },

    generateNotifications(context, timers) {
        return new Promise((resolve, reject) => {
            if (timers.length == 0) resolve()
            else {
                const timer = timers.pop()
                let notificationDate = timer.currentPendingAlarm && moment().isBefore(moment(timer.currentPendingAlarm)) ? timer.currentPendingAlarm : timer.nextAlarm
                // log("add Timer notification info:", timer, moment(notificationDate).format('YYYY-MM-DD HH:mm:ss'), timer.currentPendingAlarm, timer.nextAlarm)
                // notification.addLocalNotification({
                //     title: 'Pendiente de firmar: ' + timer.name,
                //     description: timer.description,
                //     text: '¡Es hora de hacerlo!',
                //     group: i18n.t('tools.timer_title'),
                //     ongoing: true,
                //     trigger: { at: moment(notificationDate).toDate() },
                //     data: { type: 'timerOp', timerId: timer.id, date: moment(notificationDate).format('YYYY-MM-DD HH:mm:ss') }
                // })
                setTimeout(() => {
                    context.dispatch('generateNotifications', timers)
                }, 2000)
            }
        })
    },

    getLastTimesDone(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }

        const timersCopy = structuredClone(context.state.timers)

        const timersIds = timersCopy
            .map((timer) => {
                return timer.id
            })
            .join(',')

        function floatToTimeStamp(float, offsetDays = 0) {
            const today = moment().add(offsetDays, 'days').format('YYYY-MM-DD 00:00:00')
            const timeStamp = moment(today)
                .add(float * 60, 'minutes')
                .valueOf()
            return timeStamp
        }

        return api
            .post('timer-op/timers/checked', { timers: timersIds })
            .then((response) => {
                const lastCheckedTimers = Object.values(response.data)

                timersCopy.forEach((timer) => {
                    const checked = lastCheckedTimers.find((ct) => ct.id === timer.id)

                    if (checked && checked.last_done) {
                        const lastDoneTimeStamp = moment(checked.last_done, 'YYYY-MM-DD HH:mm:ss').valueOf()
                        timer.lastAlarmDone = lastDoneTimeStamp

                        // Verificar si el timer ya no está disponible
                        if (timer.currentPendingAlarm && timer.lastAlarmDone >= timer.currentPendingAlarm) {
                            timer.isAvailable = false
                        } else {
                            // Si hay una alarma pendiente más reciente que la última firma, el timer está disponible
                            timer.isAvailable = timer.currentPendingAlarm > timer.lastAlarmDone
                        }
                    } else {
                        timer.lastAlarmDone = null
                        timer.isAvailable = true
                    }
                })

                context.commit('setTimers', timersCopy)
                db_timers.clear().then(() => {
                    timersCopy.forEach((value) => {
                        db_timers.setItem(value.id, value)
                    })
                })
            })
            .catch((error) => {
                console.error(error)
                // return loadTimersOffline(context)
            })
    },

    formatTimers(context, params) {
        if (!Array.isArray(params)) {
            return
        }

        function floatToTimeStamp(float, offsetDays = 0) {
            const today = moment().add(offsetDays, 'days').format('YYYY-MM-DD 00:00:00')
            const timeStamp = moment(today)
                .add(parseInt(float * 60), 'minutes')
                .valueOf()
            return timeStamp
        }

        function timeToDecimal(t) {
            var arr = t.split(':')
            var dec = parseInt((arr[1] / 6) * 10, 10)

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec)
        }

        const timers = Object.values(params)

        const formattedTimers = timers.map((timer) => {
            let formattedAlarms
            let nextAlarmText
            let nextAlarmText2 = false
            if (JSON.parse(timer.periodicity).typePeriodicity === 'daily') {
                if (typeof timer.times.p !== 'undefined') {
                    let planification = timer.times.p
                    let scheduleLocation = context.rootGetters['login/getConfig'].schedule
                    formattedAlarms = []
                    if (scheduleLocation && scheduleLocation.schedule) {
                        const days = ['u', 'm', 't', 'w', 'r', 'f', 's']
                        const today = moment().day()
                        let day = days[today]
                        let type = planification.type
                        let schedule = scheduleLocation.schedule[day][type]
                        // let schedule = scheduleLocation.schedule[day].type && scheduleLocation.schedule[day].c ? scheduleLocation.schedule[day].c : scheduleLocation.schedule[day] && scheduleLocation.schedule[day].r ? scheduleLocation.schedule[day].r : false
                        if (schedule) {
                            let start_date,
                                end_date = false

                            if (timer.times.p.mode == 's') {
                                start_date = moment(schedule.s, 'HH:mm')
                                end_date = moment(schedule.e, 'HH:mm')
                            } else {
                                start_date = moment(schedule.e, 'HH:mm')
                                start_date.add(-1, 'days')
                                end_date = moment(schedule.e, 'HH:mm')
                            }

                            if (end_date.isBefore(start_date)) {
                                end_date = end_date.add(1, 'days')
                            }

                            //QUE PASA SI ESTAMOS EN LA MADRUGADA Y LA HORA DE FIN ES MENOR A LA DE INICIO
                            if (!moment().isBetween(start_date, end_date)) {
                                let day2 = today > 0 ? days[today - 1] : days[6]
                                let start_date2,
                                    end_date2 = false
                                if (timer.times.p.mode == 's') {
                                    start_date2 = moment(schedule.s, 'HH:mm')
                                    end_date2 = moment(schedule.e, 'HH:mm')
                                } else {
                                    start_date2 = moment(schedule.e, 'HH:mm')
                                    end_date2 = moment(schedule.e, 'HH:mm')
                                }
                                if (end_date2.isBefore(start_date2)) {
                                    end_date2 = end_date2.add(1, 'days')
                                }
                                //SOBREESCRIBIR LOS VALORES
                                if (moment().isBetween(start_date, end_date)) {
                                    start_date = start_date2
                                    end_date = end_date2
                                    day = day2
                                }
                            }

                            // PREPARAMOS PRIMER ALARMA
                            start_date = start_date.add(planification.s, 'minutes')
                            if (start_date.isBefore(moment())) {
                                start_date.add(1, 'days')
                            }
                            formattedAlarms.push({ float: timeToDecimal(start_date.format('HH:mm')), timestamp: start_date.valueOf() })
                            // if (timer.times.p.mode == 'e' && timer.times.p.s == '-420') console.log('TEST', start_date)
                            // PREPARAMOS EL BUCLE
                            start_date.add(planification.i.e, 'minutes')
                            let it = planification.i.r
                            while (start_date.isBefore(end_date) && it > 0) {
                                formattedAlarms.push({ float: timeToDecimal(start_date.format('HH:mm')), timestamp: start_date.valueOf() })
                                start_date.add(planification.i.e, 'minutes')
                                it--
                            }
                        }
                    }
                    nextAlarmText2 = true
                } else {
                    // console.log(JSON.parse(JSON.stringify(timer)))
                    formattedAlarms = timer.times.map((float) => {
                        const formattedTime = { float: float, timestamp: floatToTimeStamp(float) }
                        return formattedTime
                    })
                }
                const nextAlarmValue = moment(timer.earliest_alarm_date).valueOf()
                const currentDate = moment().valueOf()

                if (nextAlarmValue > currentDate) {
                    nextAlarmText = moment(timer.earliest_alarm_date).format('MMM DD HH:mm')
                }
            } else {
                formattedAlarms = timer.times.map((float) => {
                    const periodicity = JSON.parse(timer.periodicity)
                    const time = moment(floatToTimeStamp(timer.times, 1)).format('HH:mm') // Hora de la alarma
                    const currentDate = moment().format('YYYY-MM-DD') // Fecha actual

                    const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, periodicity.week, currentDate)
                    // log(JSON.parse(JSON.stringify(nextAlarm)))
                    nextAlarmText = nextAlarm.nextAlarmDateTime

                    const formattedTime = { float: float, timestamp: floatToTimeStamp(float, nextAlarm.daysRemaining) }
                    return formattedTime
                })
            }

            // Obtener los timestamps de mañana, y si no existen crearlos (para evitar contador en 00:00)
            let closestNextTimestamp = null
            let smallestDifference = Infinity
            const currentTimestamp = new Date().getTime()

            for (let i = 0; i < formattedAlarms.length; i++) {
                const timeDifference = formattedAlarms[i].timestamp - currentTimestamp
                if (timeDifference > 0 && timeDifference < smallestDifference) {
                    smallestDifference = timeDifference
                    closestNextTimestamp = formattedAlarms[i].timestamp
                }
            }
            if (closestNextTimestamp === null) {
                if (JSON.parse(timer.periodicity).typePeriodicity === 'daily') {
                    if (typeof timer.times.p !== 'undefined') {
                        let planification = timer.times.p
                        let scheduleLocation = context.rootGetters['login/getConfig'].schedule
                        formattedAlarms = []
                        if (scheduleLocation && scheduleLocation.schedule) {
                            const days = ['u', 'm', 't', 'w', 'r', 'f', 's']
                            const today = moment().day()
                            let day = days[today]
                            let type = planification.type
                            let schedule = scheduleLocation.schedule[day][type]
                            if (schedule) {
                                let start_date,
                                    end_date = false
                                if (timer.times.p.mode == 's') {
                                    start_date = moment(schedule.s, 'HH:mm')
                                    end_date = moment(schedule.e, 'HH:mm')
                                } else {
                                    start_date = moment(schedule.e, 'HH:mm')
                                    end_date = moment(schedule.e, 'HH:mm')
                                }

                                if (end_date.isBefore(start_date)) {
                                    end_date = end_date.add(1, 'days')
                                }

                                //QUE PASA SI ESTAMOS EN LA MADRUGADA Y LA HORA DE FIN ES MENOR A LA DE INICIO
                                if (!moment().isBetween(start_date, end_date)) {
                                    let day2 = today > 0 ? days[today - 1] : days[6]
                                    let type2 = planification.type
                                    let schedule2 = scheduleLocation.schedule[day2][type2]
                                    // let schedule2 = planification.type == 'c' ? (scheduleLocation.schedule[day2].c ? scheduleLocation.schedule[day2].c : false) : scheduleLocation.schedule[day2].r ? scheduleLocation.schedule[day2].r : false
                                    if (schedule2) {
                                        let start_date2,
                                            end_date2 = false
                                        if (timer.times.p.mode == 's') {
                                            start_date2 = moment(schedule.s, 'HH:mm')
                                            end_date2 = moment(schedule.e, 'HH:mm')
                                        } else {
                                            start_date2 = moment(schedule.e, 'HH:mm')
                                            end_date2 = moment(schedule.e, 'HH:mm')
                                        }
                                        if (end_date2.isBefore(start_date2)) {
                                            end_date2 = end_date2.add(1, 'days')
                                        }
                                        //SOBREESCRIBIR LOS VALORES
                                        if (moment().isBetween(start_date, end_date)) {
                                            start_date = start_date2
                                            end_date = end_date2
                                            day = day2
                                        }
                                    }
                                }

                                // PREPARAMOS PRIMER ALARMA
                                start_date = start_date.add(planification.s, 'minutes')
                                if (start_date.isBefore(moment())) {
                                    start_date.add(1, 'days')
                                }
                                formattedAlarms.push({ float: timeToDecimal(start_date.format('HH:mm')), timestamp: start_date.valueOf() })
                                // PREPARAMOS EL BUCLE
                                start_date.add(planification.i.e, 'minutes')
                                let it = planification.i.r
                                while (start_date.isBefore(end_date) && it > 0) {
                                    formattedAlarms.push({ float: timeToDecimal(start_date.format('HH:mm')), timestamp: start_date.valueOf() })
                                    start_date.add(planification.i.e, 'minutes')
                                    it--
                                }
                            }
                        }
                    } else {
                        const formattedAlarmsTomorrow = timer.times.map((float) => {
                            return { float: float, timestamp: floatToTimeStamp(float, 1) }
                        })
                        formattedAlarms.push(...formattedAlarmsTomorrow)
                    }
                } else {
                    const periodicity = JSON.parse(timer.periodicity)
                    const time = moment(floatToTimeStamp(timer.times, 1)).format('HH:mm') // Hora de la alarma
                    const currentDate = moment().format('YYYY-MM-DD') // Fecha actual

                    const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, periodicity.week, currentDate)
                    nextAlarmText = nextAlarm.nextAlarmDateTime

                    const formattedAlarmsTomorrow = timer.times.map((float) => {
                        return { float: float, timestamp: floatToTimeStamp(float, nextAlarm.daysRemaining) }
                    })
                    formattedAlarms.push(...formattedAlarmsTomorrow)
                }
            }

            const formattedTimer = {
                ...timer,
                times: formattedAlarms,
                isAvailable: false,
                previousAlarm: null,
                currentPendingAlarm: null,
                nextAlarm: null,
                lastAlarmDone: null,
                nextAlarmText: nextAlarmText,
                hideTimer: false
            }

            // if (nextAlarmText2) {
            // console.log('TEST2', formattedTimer)
            // }

            return JSON.parse(JSON.stringify(formattedTimer))
        })

        // setear Timers con los times formateados:
        context.commit('setTimers', formattedTimers)
        db_timers.clear().then(() => {
            formattedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
    },

    updateTimers(context, params) {
        const currentTimestamp = moment().valueOf()

        const updatedTimers = state.timers.map((timer) => {
            const periodicity = JSON.parse(timer.periodicity)
            const isWeeklyOrBiweekly = ['weekly', 'biweekly', 'monthly', 'quarterly'].includes(periodicity.typePeriodicity)

            const dateCreated = moment(timer.earliest_alarm_date).valueOf()
            if (isWeeklyOrBiweekly) {
                // Para timers semanales y quincenales
                const time = moment(timer.times[0].timestamp).format('HH:mm')
                const currentDate = moment().format('YYYY-MM-DD')
                const previousAlarm = getPreviousAlarmDate(periodicity.days, time, periodicity.typePeriodicity, periodicity.week, currentDate)
                timer.previousAlarm = moment(previousAlarm.previousAlarmDateTime).valueOf()

                const nextAlarm = getNextAlarmDate(periodicity.days, time, periodicity.typePeriodicity, periodicity.week, currentDate)
                timer.nextAlarm = moment(nextAlarm.nextAlarmDateTime, 'MMM DD HH:mm').valueOf()
                // if (periodicity.typePeriodicity === 'monthly') console.log('monthly', timer.lastAlarmDone, timer.previousAlarm, timer.currentPendingAlarm, currentTimestamp, dateCreated)
                if (!timer.lastAlarmDone && dateCreated >= timer.previousAlarm && dateCreated >= currentTimestamp && !timer.currentPendingAlarm) {
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                    timer.nextAlarmText = nextAlarm.nextAlarmDateTime
                } else if (!timer.lastAlarmDone && dateCreated >= timer.previousAlarm && dateCreated <= timer.previousAlarm) {
                    timer.isAvailable = moment(timer.earliest_alarm_date).isSameOrBefore(moment())
                    timer.currentPendingAlarm = moment(timer.earliest_alarm_date).valueOf()
                    timer.nextAlarm = moment(timer.earliest_alarm_date).valueOf()
                    timer.nextAlarmText = moment(timer.earliest_alarm_date).format('MMM DD HH:mm')
                } else if (timer.lastAlarmDone < timer.previousAlarm && dateCreated >= currentTimestamp) {
                    timer.isAvailable = true
                    timer.currentPendingAlarm = timer.previousAlarm
                    timer.nextAlarmText = previousAlarm.previousAlarmDateTimeText
                } else {
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                    timer.nextAlarmText = nextAlarm.nextAlarmDateTime
                }
            } else {
                // Para timers diarios, mantener la lógica existente
                let closestPreviousAlarmTimestamp = null
                let smallestDifference = Infinity

                const timestamps = timer.times.map((time) => time.timestamp)

                for (let i = 0; i < timestamps.length; i++) {
                    let timeDifference = currentTimestamp - timestamps[i]

                    if (timeDifference >= 0 && timeDifference < smallestDifference && timer.lastAlarmDone != closestPreviousAlarmTimestamp) {
                        smallestDifference = timeDifference
                        closestPreviousAlarmTimestamp = timestamps[i]
                    }
                }
                // console.log(timer.lastAlarmDone, timer.previousAlarm, timer.currentPendingAlarm, currentTimestamp, dateCreated, closestPreviousAlarmTimestamp)
                timer.previousAlarm = closestPreviousAlarmTimestamp

                if (closestPreviousAlarmTimestamp !== null) {
                    timer.currentPendingAlarm = closestPreviousAlarmTimestamp
                } else {
                    timer.currentPendingAlarm = null
                }

                if (dateCreated < currentTimestamp && closestPreviousAlarmTimestamp <= currentTimestamp && closestPreviousAlarmTimestamp > timer.lastAlarmDone) {
                    // log('ntro 1')
                    timer.isAvailable = true
                } else if (!timer.lastAlarmDone && dateCreated < currentTimestamp && !closestPreviousAlarmTimestamp) {
                    // log('ntro 4')
                    timer.isAvailable = true
                    const timestamps2 = timer.times.map((time) => moment(time.timestamp).add(-1, 'days').valueOf())
                    smallestDifference = Infinity
                    for (let i = 0; i < timestamps2.length; i++) {
                        let timeDifference = currentTimestamp - timestamps2[i]

                        if (timeDifference >= 0 && timeDifference < smallestDifference) {
                            smallestDifference = timeDifference
                            closestPreviousAlarmTimestamp = timestamps2[i]
                        }
                    }
                    timer.currentPendingAlarm = moment(closestPreviousAlarmTimestamp).valueOf()
                } else if (!closestNextTimestamp) {
                    // log('entro 3')
                    timer.isAvailable = false
                    timer.nextAlarm = moment(timer.earliest_alarm_date).valueOf()
                } else {
                    // log('ntro 2')
                    timer.isAvailable = false
                    timer.currentPendingAlarm = null
                }

                // Calcular la próxima alarma
                let closestNextTimestamp = null
                smallestDifference = Infinity

                for (let i = 0; i < timestamps.length; i++) {
                    const timeDifference = timestamps[i] - currentTimestamp
                    if (timeDifference > 0 && timeDifference < smallestDifference) {
                        smallestDifference = timeDifference
                        closestNextTimestamp = timestamps[i]
                    }
                }

                if (closestNextTimestamp !== null) {
                    timer.nextAlarm = closestNextTimestamp
                } else {
                    timer.nextAlarm = timestamps[0]
                }
            }

            return timer
        })

        context.commit('setTimers', updatedTimers)
    },

    setupInterval(context, params) {
        if (state.interval) {
            clearInterval(state.interval)
        }

        const interval = setInterval(() => {
            context.dispatch('updateTimers')
        }, 1000)

        context.commit('setInterval', interval)
    },
    setHideAlarm(context, params) {
        const { alarmId } = params
        const timerIndex = state.timers.findIndex((timer) => timer.id === alarmId)
        if (timerIndex !== -1) {
            Vue.set(state.timers[timerIndex], 'hideTimer', true)
        } else {
            console.error(`Timer with id ${alarmId} not found`)
        }
    },

    resetAllHideTimers(context) {
        console.log('Timers antes:', state.timers) // Verifica el array completo
        state.timers.forEach((timer, index) => {
            isMoreThanSixHours(timer.nextAlarm, timer.id)
            console.log(`Procesando timer ${index}:`, timer)
            timer.hideTimer = false
        })
        console.log('Timers después:', state.timers) // Verifica los cambios
    },
    backAllHideTimers(context) {
        console.log('Timers antes:', state.timers) // Verifica el array completo
        state.timers.forEach((timer, index) => {
            console.log(`Procesando timer ${index}:`, timer)
            timer.hideTimer = isMoreThanSixHours(timer.nextAlarm, timer.isAvailable)
        })
        console.log('Timers después:', state.timers) // Verifica los cambios
    },
    setAlarmAsDone(context, params) {
        let requireSync = !(fnCheckConnection() && context.rootGetters.getFastConnection) && context.rootGetters.getOffline ? true : undefined

        const { alarm, checkDateTimestamp } = params

        // update Timers view
        const timersCopy = structuredClone(state.timers)

        let updatedAlarm = null

        const updatedTimers = timersCopy.map((timer) => {
            if (timer.id === alarm.id) {
                const currentPendingAlarm = alarm.currentPendingAlarm

                updatedAlarm = {
                    ...timer,
                    lastAlarmDone: currentPendingAlarm,
                    isAvailable: false,
                    currentPendingAlarm: null
                }

                return updatedAlarm
            } else return timer
        })

        context.commit('setTimers', updatedTimers)
        db_timers.clear().then(() => {
            updatedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
        const employeeId = context.rootGetters['loginUser/getLocalEmployee']
        const formattedCheckedAlarm = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD HH:mm:ss')
        const formattedBusinessDate = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD')
        const formattedCheckDate = moment(checkDateTimestamp).format('YYYY-MM-DD HH:mm:ss')

        const alarmData = {
            employee_id: employeeId,
            alarm_date: formattedCheckedAlarm,
            business_date: formattedBusinessDate,
            check_date: formattedCheckDate
        }

        if (requireSync) {
            let id = uuidv4()
            db_checks_to_sync.setItem(id, { ...alarmData, alarm_id: updatedAlarm.id, id }).then(() => {
                context.dispatch('getCountSyncItems', {}, { root: true })
            })
            return true
        }

        notification.removeLocalNotification({
            type: 'timerOp',
            timerId: updatedAlarm.id,
            date: formattedCheckedAlarm
        })

        // update in API
        return api
            .post(`timer-op/timers/${updatedAlarm.id}`, alarmData)
            .then((response) => {
                const timers = context.getters.getTimers
                let timersNotification = timers.filter((timer) => timer.id == updatedAlarm.id)
                context.dispatch('generateNotifications', timersNotification)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    syncChecks(context, params) {
        let checks = []
        db_checks_to_sync
            .iterate(function (value) {
                checks.push(value)
            })
            .then(async function () {
                const groupedChecks = checks.reduce((acc, check) => {
                    acc[check.alarm_id] = acc[check.alarm_id] || []
                    acc[check.alarm_id].push(check)
                    return acc
                }, {})

                Object.values(groupedChecks).forEach((group) => {
                    group.sort((a, b) => new Date(a.alarm_date) - new Date(b.alarm_date))
                })

                const batches = []

                let batch = {}
                let batchCount = 0

                for (const [alarm_id, group] of Object.entries(groupedChecks)) {
                    while (group.length > 0) {
                        if (!batch[alarm_id]) {
                            batch[alarm_id] = []
                        }

                        const toAdd = group.splice(0, 10 - batchCount)
                        batch[alarm_id] = batch[alarm_id].concat(toAdd)
                        batchCount += toAdd.length

                        if (batchCount >= 10) {
                            batches.push(batch)
                            batch = {}
                            batchCount = 0
                        }
                    }
                }

                if (batchCount > 0) {
                    batches.push(batch)
                }

                for (const batch of batches) {
                    await api
                        .post(`timer-op/sync`, { timers: JSON.stringify(batch) })
                        .then((res) => {
                            if (res.status == true) {
                                const idsToDelete = Object.values(batch)
                                    .flat()
                                    .map((check) => check.id)
                                idsToDelete.forEach((id) => db_checks_to_sync.removeItem(id))
                                context.dispatch('getCountSyncItems', {}, { root: true })
                            }
                        })
                        .catch(function (error) {
                            logError(error)
                            return false
                        })
                }

                context.dispatch('getCountSyncItems', {}, { root: true })
            })
    },
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            db_checks_to_sync
                .iterate(function (value, key, iterationNumber) {
                    count++
                })
                .then(function () {
                    resolve(count)
                })
        })
    }
}

function isMoreThanSixHours(nextAlarm, isAvailable) {
    var isMoreThanSixHours = false
    if (nextAlarm && !isAvailable) {
        const targetTime = new Date(nextAlarm)
        const now = new Date()
        const timeRemaining = targetTime - now

        const totalSeconds = Math.floor(timeRemaining / 1000)
        const totalMinutes = Math.floor(totalSeconds / 60)
        const totalHours = Math.floor(totalMinutes / 60)

        const moreThanSixHours = totalHours >= getTimeFilter()
        if (isMoreThanSixHours !== moreThanSixHours) {
            isMoreThanSixHours = moreThanSixHours
        }
    }

    console.log('HOM', nextAlarm, isAvailable, isMoreThanSixHours)
    return isMoreThanSixHours
}

function getTimeFilter() {
    const config = store.state.login.config
    return config.Timersh || config.Timersh > 0 ? config.Timersh : 6
}

function getNextAlarmDate(days, time, frequency, week = null, currentDate = moment().format('YYYY-MM-DD')) {
    // console.log(`getNextAlarDate('${days}', ${time}, ${frequency}, ${week})`)
    const now = moment(currentDate).startOf('day')
    const currentTime = moment()
    const targetTime = moment(time, 'HH:mm')
    let nextAlarm = null

    if (frequency === 'quarterly') {
        var months = days
    }

    // Lógica actual para frecuencias semanal y quincenal
    for (let i = 0; i <= 7; i++) {
        const dayOffset = (now.day() + i) % 7
        if (days.includes((dayOffset + 6) % 7)) {
            const potentialDate = now.clone().add(i, 'days').set({
                hour: targetTime.hour(),
                minute: targetTime.minute()
            })

            if (i === 0 && moment().isBefore(potentialDate)) {
                nextAlarm = potentialDate
            } else if (i > 0) {
                nextAlarm = potentialDate
            }

            if (nextAlarm) break
        }
    }

    if (nextAlarm && nextAlarm.isSameOrBefore(moment(), 'minute')) {
        if (frequency === 'biweekly') {
            nextAlarm = nextAlarm.add(14, 'days')
        } else {
            nextAlarm = nextAlarm.add(7, 'days')
        }
    }
    // Caso Mensual
    if (frequency === 'monthly' && week !== null) {
        const daysOfWeek = days.map((day) => (day + 6) % 7) // Convertimos días a formato moment (0=Domingo, 1=Lunes...)
        let foundValidAlarm = false

        // Inicio de la semana especificada del mes
        const startOfMonth = now.clone().startOf('month')
        let weekStartDate = startOfMonth.clone().add(week - 1, 'weeks')

        // Buscar el día de la semana más cercano en la primera semana
        for (let i = 0; i < daysOfWeek.length; i++) {
            const targetDayOfWeek = daysOfWeek[i]
            let targetAlarm = weekStartDate.clone().day(targetDayOfWeek).set({
                hour: targetTime.hour(),
                minute: targetTime.minute(),
                second: 0
            })

            // Si la alarma es antes de la fecha y hora actuales, mover a la siguiente semana
            if (targetAlarm.isBefore(currentTime)) {
                targetAlarm.add(7, 'days')
            }

            // Al encontrar un próximo día y hora válidos, establecemos la alarma
            if (targetAlarm.isAfter(currentTime)) {
                nextAlarm = targetAlarm
                foundValidAlarm = true
                break
            }
        }

        // Si no se encontró una alarma válida, buscamos en la próxima semana permitida
        if (!foundValidAlarm) {
            weekStartDate = weekStartDate.add(1, 'week').startOf('week')

            for (let i = 0; i < daysOfWeek.length; i++) {
                const targetDayOfWeek = daysOfWeek[i]
                const targetAlarm = weekStartDate.clone().day(targetDayOfWeek).set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })

                nextAlarm = targetAlarm
                break
            }
        }
    }

    // Nuevo caso: Trimestral
    if (frequency === 'quarterly' && Array.isArray(days) && days.length > 0) {
        const months = days
        const currentMonth = now.month()
        let foundNextQuarterDay = false

        // Revisamos si el mes actual está en el arreglo de meses objetivo
        if (months.includes(currentMonth)) {
            // Si la hora actual aún no ha pasado, programa la alarma para hoy
            if (targetTime.isAfter(currentTime)) {
                nextAlarm = now.clone().set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })
                foundNextQuarterDay = true
            } else {
                // Si la hora actual ya ha pasado, programa la alarma para el próximo día
                nextAlarm = now.clone().add(1, 'day').set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })
                foundNextQuarterDay = true
            }
        }

        // Si no encontramos un día válido en el mes actual, busca el primer mes válido en el próximo año
        if (!foundNextQuarterDay) {
            for (let i = 0; i < months.length; i++) {
                const month = months[i]
                if (month > currentMonth || (month === currentMonth && targetTime.isAfter(currentTime))) {
                    nextAlarm = now.clone().month(month).date(1).set({
                        hour: targetTime.hour(),
                        minute: targetTime.minute(),
                        second: 0
                    })
                    foundNextQuarterDay = true
                    break
                }
            }

            // Si no se encuentra un mes válido, programa para el primer mes objetivo del próximo año
            if (!foundNextQuarterDay) {
                nextAlarm = now.clone().add(1, 'year').month(months[0]).date(1).set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })
            }
        }
    }

    const totalMinutes = nextAlarm.diff(moment(), 'minutes')
    const exactDays = totalMinutes / (24 * 60)
    const daysRemaining = Math.floor(exactDays)
    const hoursRemaining = Math.floor((exactDays - daysRemaining) * 24)

    return {
        nextAlarmDateTime: nextAlarm.format('MMM DD HH:mm'),
        daysRemaining: daysRemaining + hoursRemaining / 24,
        interval: exactDays.toFixed(2)
    }
}

function getPreviousAlarmDate(days, time, frequency, week = null, currentDate = moment().format('YYYY-MM-DD')) {
    const now = moment(currentDate).startOf('day')
    const targetTime = moment(time, 'HH:mm')
    let previousAlarm = null

    // Lógica actual para frecuencias semanal y quincenal
    for (let i = 0; i <= 7; i++) {
        const dayOffset = (now.day() - i + 7) % 7
        if (days.includes((dayOffset + 6) % 7)) {
            const potentialDate = now.clone().subtract(i, 'days').set({
                hour: targetTime.hour(),
                minute: targetTime.minute()
            })

            if (i === 0 && moment().isAfter(potentialDate)) {
                previousAlarm = potentialDate
            } else if (i > 0) {
                previousAlarm = potentialDate
            }

            if (previousAlarm) break
        }
    }

    if (previousAlarm && previousAlarm.isSameOrAfter(moment(), 'minute')) {
        if (frequency === 'biweekly') {
            previousAlarm = previousAlarm.subtract(14, 'days')
        } else {
            previousAlarm = previousAlarm.subtract(7, 'days')
        }
    }

    // Nuevo caso: Mensual
    if (frequency === 'monthly' && week !== null) {
        const daysOfWeek = days.map((day) => (day + 6) % 7) // Convertimos días a formato moment (0=Domingo, 1=Lunes...)
        let foundValidAlarm = false

        // Inicio de la semana especificada del mes
        const startOfMonth = now.clone().startOf('month')
        let weekStartDate = startOfMonth.clone().add(week - 1, 'weeks')

        // Busca,ps el día de la semana más cercano en la primera semana
        for (let i = daysOfWeek.length - 1; i >= 0; i--) {
            const targetDayOfWeek = daysOfWeek[i]
            let targetAlarm = weekStartDate.clone().day(targetDayOfWeek).set({
                hour: targetTime.hour(),
                minute: targetTime.minute(),
                second: 0
            })

            // Si la alarma es después de la fecha y hora actuales, mover a la semana anterior
            if (targetAlarm.isAfter(now)) {
                targetAlarm.subtract(7, 'days')
            }

            // Al encontrar un día y hora válidos antes de la fecha actual, establecemos la alarma
            if (targetAlarm.isBefore(now)) {
                previousAlarm = targetAlarm
                foundValidAlarm = true
                break
            }
        }

        // Si no se encontró una alarma válida, buscamos en la semana anterior permitida
        if (!foundValidAlarm) {
            weekStartDate = weekStartDate.subtract(1, 'week').startOf('week')

            for (let i = daysOfWeek.length - 1; i >= 0; i--) {
                const targetDayOfWeek = daysOfWeek[i]
                const targetAlarm = weekStartDate.clone().day(targetDayOfWeek).set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })

                previousAlarm = targetAlarm
                break
            }
        }
    }

    // Nuevo caso: Trimestral
    if (frequency === 'quarterly' && Array.isArray(days) && days.length > 0) {
        const months = days
        const currentMonth = now.month()
        let foundPreviousQuarterDay = false

        // Revisamos si el mes actual está en el arreglo de meses objetivo
        if (months.includes(currentMonth)) {
            // Si la hora actual ya ha pasado, programa la alarma para hoy
            if (targetTime.isBefore(currentTime)) {
                previousAlarm = now.clone().set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })
                foundPreviousQuarterDay = true
            } else {
                // Si la hora actual aún no ha pasado, programa la alarma para el día anterior
                previousAlarm = now.clone().subtract(1, 'day').set({
                    hour: targetTime.hour(),
                    minute: targetTime.minute(),
                    second: 0
                })
                foundPreviousQuarterDay = true
            }
        }

        // Si no encontramos un día válido en el mes actual, busca el último mes válido en el año pasado
        if (!foundPreviousQuarterDay) {
            for (let i = months.length - 1; i >= 0; i--) {
                const month = months[i]
                if (month < currentMonth || (month === currentMonth && targetTime.isBefore(currentTime))) {
                    previousAlarm = now.clone().month(month).endOf('month').set({
                        hour: targetTime.hour(),
                        minute: targetTime.minute(),
                        second: 0
                    })
                    foundPreviousQuarterDay = true
                    break
                }
            }

            // Si no se encuentra un mes válido, programa para el último mes objetivo del año pasado
            if (!foundPreviousQuarterDay) {
                previousAlarm = now
                    .clone()
                    .subtract(1, 'year')
                    .month(months[months.length - 1])
                    .endOf('month')
                    .set({
                        hour: targetTime.hour(),
                        minute: targetTime.minute(),
                        second: 0
                    })
            }
        }
    }

    const totalMinutes = moment().diff(previousAlarm, 'minutes')
    const exactDays = totalMinutes / (24 * 60)
    const daysElapsed = Math.floor(exactDays)
    const hoursElapsed = Math.floor((exactDays - daysElapsed) * 24)

    return {
        previousAlarmDateTime: previousAlarm.format('YYYY-MM-DD HH:mm:ss'),
        previousAlarmDateTimeText: previousAlarm.format('MMM DD HH:mm')
    }
}

function loadTimersOffline(context) {
    let timers = []
    return db_timers
        .iterate(function (value, key, iterationNumber) {
            timers.push(value)
        })
        .then(function () {
            timers.sort((a, b) => a.i - b.i)
            context.commit('setTimers', timers)
            context.dispatch('updateTimers')
            context.dispatch('setupInterval')
        })
}

const mutations = {
    setTimers(state, payload) {
        state.timers = payload
        Vue.set(state, 'timers', payload)
    },
    setInterval(state, data) {
        state.interval = data
    },
    setChecksToSync(state, data) {
        state.checksToSync = data
    },
    setCategories(state, categories) {
        state.categories = categories
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
